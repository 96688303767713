<template>
    <vs-table
        :onlyClickCheckbox="true"
        ref="table"
        v-model="selected"
        :sst="true"
        :data="logs"
    >
        <div slot="header" class="flex items-center flex-grow justify-end">
            <div>
                <vs-input icon="search" placeholder="Search" v-model="search" />
            </div>
        </div>

        <template slot="thead">
            <vs-th>Client</vs-th>
            <vs-th>Date Added</vs-th>
            <vs-th>Total Products</vs-th>
            <vs-th>Total Commission</vs-th>
        </template>

        <template slot-scope="{ data }">
            <tbody>
                <vs-tr :data="log" :key="index" v-for="(record, index) in data">
                    <vs-td> {{ record.name }} </vs-td>

                    <vs-td>
                        {{ formatDate(record.date_added) }}
                    </vs-td>
                    <vs-td>£{{ record.total }}</vs-td>

                    <vs-td>£{{ record.total_commission }}</vs-td>

                    <template slot="expand">
                        <div class="w-full flat" id="new">
                            <vs-table :sst="true" :data="data" :noDataText="''">
                                <template slot="thead">
                                    <vs-th>Product</vs-th>
                                    <vs-th></vs-th>
                                    <vs-th>Product Total</vs-th>
                                    <vs-th>Commission Total</vs-th>
                                </template>
                                <vs-tr
                                    v-for="(com, index) in record.commissions"
                                    :key="index"
                                >
                                    <vs-td>{{ com.title }}</vs-td>
                                    <vs-td> </vs-td>
                                    <vs-td> £{{ com.total }}</vs-td>
                                    <vs-td> £{{ com.commission }}</vs-td>
                                </vs-tr>
                            </vs-table>
                        </div>
                    </template>
                </vs-tr>
            </tbody>
        </template>
    </vs-table>
</template>

<script>
import { textFormat } from '@/mixins/textFormat'

export default {
    name: 'CommissionRecordTable',
    mixins: [textFormat],
    props: ['data', 'itemsPerPage', 'view'],
    computed: {
        logs() {
            return this.data.data
        },
        search: {
            set(value) {
                if (value.length > 1 || value === '') {
                    this.searchQuery = value
                    this.$emit('search', value)
                }
            },
            get() {
                return this.searchQuery
            },
        },
    },
    data() {
        return {
            selected: [],
            searchQuery: '',
            status: 'All',
            selectMultiple: false,
        }
    },
    methods: {
        toggleSelect() {
            this.selected = []
            this.selectMultiple = !this.selectMultiple
        },
        setFilter(status, value) {
            this.status = status
            this.$emit('filter', value)
        },
    },
}
</script>
